<script>


import {ToolIcon} from "@zhuowenli/vue-feather-icons";

export default {
  components: {
    ToolIcon,
  },
  name: "ReleaseProjeto",
  data() {
    return {
      release: '1.0.0',
      date: '1.0.0',
      env: '-',
    }
  },
  methods: {
    getRelease() {

      //Pegar o release do package.json

      const version = require('../../package.json').version;
      const date = require('../../package.json').date;

      //Pegar o NODE_ENV
      const env = process.env.NODE_ENV;

      this.release = version;
      this.date = date;
      this.env = env;
    }
  },
  mounted() {
    this.getRelease();
  }
}
</script>

<template>

  <span class="spanRelease">
     <small class="small">
     <ToolIcon></ToolIcon> Versão do sistema {{ release }} -  {{ date }} - {{ env }}
       </small>
  </span>
</template>

<style scoped>

.spanRelease {
  color: #9aaab3;
}

</style>
