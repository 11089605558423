<template>
  <div>
    <div id="layout-wrapper">
      <NavBar />
      <!-- ========== App Menu ========== -->
      <div class="app-menu navbar-menu">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <!-- Dark Logo-->
          <router-link to="/" class="logo logo-dark">
            <span class="logo-sm">
              <img src="@/assets/images/logo-sm.png" alt="" height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-dark.png" alt="" height="17" />
            </span>
          </router-link>
          <!-- Light Logo-->
          <router-link to="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="@/assets/images/logo-sm.png" alt="" height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-light.png" alt="" height="17" />
            </span>
          </router-link>
          <button type="button" class="
              btn btn-sm
              p-0
              fs-20
              header-item
              float-end
              btn-vertical-sm-hover
            " id="vertical-hover">
            <i class="ri-record-circle-line"></i>
          </button>
        </div>
        <div id="scrollbar">
          <div class="container-fluid">
            <ul class="navbar-nav h-100" id="navbar-nav">

              <li class="nav-item" v-if="dash_easyux">
                <a class="nav-link menu-link"
                   href="#easyux"
                   data-bs-toggle="collapse"
                   role="button"
                   aria-expanded="false" aria-controls="easyux">
                  <ph-gauge :size="24" />
                  <span>
                    EASY UX
                  </span>
                </a>
                <div class="collapse menu-dropdown" id="easyux">
                  <ul class="nav nav-sm flex-column">
                    <li class="nav-item">
                      <router-link to="/dashboard/easyux"
                                   class="nav-link custom-abc">
                        Dashboard
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/paciente/pacientes"
                                   class="nav-link custom-abc">
                        Pacientes
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/usuario/usuarios"
                                   class="nav-link custom-abc">
                        Usuários
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/calculadora"
                                   class="nav-link custom-abc">
                        Calculadora
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/suporte/suportes"
                                   class="nav-link custom-abc">
                        Suportes
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/easyanest/pageblank"
                                   class="nav-link custom-abc">
                        Desenvolvimento
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/medstory/historias"
                                   class="nav-link custom-abc">
                        Histórias
                      </router-link>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item" v-if="menu_nfe">
                <a class="nav-link menu-link"
                   href="#menu_nfe"
                   data-bs-toggle="collapse"
                   role="button"
                   aria-expanded="false" aria-controls="menu_nfe">
                  <ph-scroll :size="24" />
                  <span>
                    NF-e
                  </span>
                </a>
                <div class="collapse menu-dropdown" id="menu_nfe">
                  <ul class="nav nav-sm flex-column">
                    <li class="nav-item">
                      <router-link to="/nfe/configurar"
                                   class="nav-link custom-abc">
                        Configurar NF-e
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/nfe/lista"
                                   class="nav-link custom-abc">
                        Listar notas
                      </router-link>
                    </li>

                  </ul>
                </div>
              </li>
              <li class="nav-item" v-if="menu_equipes">
                <a class="nav-link menu-link"
                   href="#menu_equipes"
                   data-bs-toggle="collapse"
                   role="button"
                   aria-expanded="false" aria-controls="menu_equipes">
                  <ph-users-three :size="24" />
                  <span>
                    Equipes
                  </span>
                </a>
                <div class="collapse menu-dropdown" id="menu_equipes">
                  <ul class="nav nav-sm flex-column">
                    <li class="nav-item">
                      <router-link to="/equipe"
                                   class="nav-link custom-abc">
                        Minhas equipes
                      </router-link>
                    </li>
                  </ul>
                </div>
              </li>

            </ul>
          </div>
          <!-- Sidebar -->
        </div>
        <!-- Left Sidebar End -->
        <!-- Vertical Overlay-->
        <div class="vertical-overlay"></div>
      </div>
      <!-- ============================================================== -->
      <!-- Start Page Content here -->
      <!-- ============================================================== -->

      <div class="main-content">
        <div class="page-content">
          <!-- Start Content-->
          <div class="container-fluid">
            <slot />
          </div>
        </div>
        <Footer />
        <RightBar class="d-none"/>
      </div>

    </div>
  </div>
</template>
<script>
import NavBar from "@/components/nav-bar";
import Footer from "@/components/footer";
import RightBar from "@/components/right-bar.vue";


export default {
  watch: {
    $route: {
      handler: "onRoutechange",
      immediate: true,
      deep: true,
    },
  },
  methods: {
    onRoutechange(ele) {
      this.initActiveMenu(ele.path);
    },
    initActiveMenu(ele) {
      setTimeout(() => {
        if (document.querySelector("#navbar-nav")) {
          let a = document
              .querySelector("#navbar-nav")
              .querySelector('[href="' + ele + '"]');

          if (a) {
            a.classList.add("active");
            let parentCollapseDiv = a.closest(".collapse.menu-dropdown");
            if (parentCollapseDiv) {
              parentCollapseDiv.classList.add("show");
              parentCollapseDiv.parentElement.children[0].classList.add(
                  "active"
              );
              parentCollapseDiv.parentElement.children[0].setAttribute(
                  "aria-expanded",
                  "true"
              );
              if (
                  parentCollapseDiv.parentElement.closest(
                      ".collapse.menu-dropdown"
                  )
              ) {
                parentCollapseDiv.parentElement
                    .closest(".collapse")
                    .classList.add("show");
                if (
                    parentCollapseDiv.parentElement.closest(".collapse")
                        .previousElementSibling
                )
                  parentCollapseDiv.parentElement
                      .closest(".collapse")
                      .previousElementSibling.classList.add("active");
              }
            }
          }
        }
      }, 1000);
    },
  },
  components: {
    RightBar,
    NavBar,
    Footer
  },
  data() {
    return {
      dash_easyux : false,
      menu_nfe : false,
      menu_equipes : false,
    }
  },
  mounted() {

    try {
      localStorage.setItem("rightbar_isopen", false);

      /****
       * Monta o menu conforme o perfil do usuário
       */
      const TipoUsuarioId = parseInt(localStorage.getItem('user-Perfil'));
      console.log(TipoUsuarioId);
      if(TipoUsuarioId === 1){
        this.dash_easyux = true;
        this.menu_nfe = true;
        this.menu_equipes = true;
      }
      if(TipoUsuarioId === 26){
        this.menu_nfe = true;
        this.menu_equipes = true;
      }
      if(TipoUsuarioId === 27){
        this.menu_nfe = true;
        this.menu_equipes = true;
      }
      if(TipoUsuarioId === 30){
        this.menu_nfe = true;
        this.menu_equipes = true;
      }
      if(TipoUsuarioId === 60){
        this.menu_nfe = true;
        this.menu_equipes = true;
      }

    }catch (e){
      console.log(e);
    }

  }
};
</script>
<style scoped>
.nav-link.menu-link {
  padding: 12px 24px 12px 24px !important;
}
</style>